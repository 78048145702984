<template>
  <div class="section">
    <div class="transaction__filter--container d-block bg-white py-2">
      <b-row class="search__export align-items-center">
        <b-col cols="10">
          <div class="d-flex pl-2">
            <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
              :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
              @click="
                filter.sort_type == 'asc'
                  ? (filter.sort_type = 'desc')
                  : (filter.sort_type = 'asc')
                " />
            <div class="search__input w-100 pl-2 pr-0 mr-0">
              <b-input-group>
                <b-form-input v-model="filter.search" placeholder="Cari Nama Cabang" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="2" class="px-0">
          <div v-if="checkPermission('add cabang')">
            <b-button class="py-1 border-8" v-b-modal.modal-cabang @click="cleanUpForm()" v-if="slotAddBranchItem > 0">
              Tambah Cabang
            </b-button>
            <b-button class="py-1 border-8" @click="alertMaximum" v-else>
              Tambah Cabang
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <h4 class="mb-0 text-black-2 fw-bold-700 size16 pb-1 pl-1 bg-white">
      Daftar Cabang ({{ items.length }} /
      {{ items.length + slotAddBranchItem }})
    </h4>
    <!-- {{ rows.data.data }} -->
    <div class="d-flex justify-content-center mb-1 mt-3" v-if="isLoading">
      <b-spinner label="Loading..." />
    </div>
    <vue-good-table v-else max-height="80vh" :columns="columns" :rows="items" :fixed-header="false" :sort-options="{
      enabled: false,
    }" :pagination-options="{
      enabled: false,
    }">
      <template slot="table-column" slot-scope="props">
        <span v-if="props.column.field == 'action'" />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'name'">
          <span>{{ props.row.name }}</span>
        </span>
        <span v-else-if="props.column.field === 'address'">
          <span>{{ props.row.address }}</span>
        </span>
        <span v-else-if="props.column.field === 'city'">
          <span>{{ props.row.city }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="d-flex">
              <b-button v-if="checkPermission('delete cabang')" class="mr-1 px-1 border-0 bg-pink"
                @click="deleteData(props.row.uuid)">
                <feather-icon size="18" class="text-danger" icon="Trash2Icon" />
              </b-button>
              <b-button v-if="checkPermission('update cabang')" class="bg-white" @click="editItem(props.row)">
                <b-img :src="require('@/assets/images/icons/Process2.svg')" />
                Ubah
              </b-button>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border" style="padding: 0.8rem">
      <div class="d-flex align-items-center">
        <b-form-select v-model="filter.per_page" :options="['10', '15', '20']" class="mx-1" />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.data.total > 0
              ? filter.per_page * (rows.data.current_page - 1) + 1
              : 0
          }}
          ke
          {{
            rows.data.total > 0
              ? Math.min(
                filter.per_page * rows.data.current_page,
                rows.data.total
              )
              : 0
          }}
          dari {{ rows.data.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination class="mb-0" :data="rows.data" :limit="4" align="right" @pagination-change-page="getData" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
// import ModalBank from '@/components/Master/Bank/Modal/ModalBank.vue'

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    // ModalBank,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  props: {
    typeTable: {
      type: String,
    },
    result: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    slotAddBranch: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    deleteData: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { merchant } = JSON.parse(localStorage.getItem('userData'))
    const { branch_slot, user_slot } = merchant

    return {
      branch_total_registered: 0,
      branch_slot,
      user_slot,
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama",
          field: "name",
        },
        {
          label: "Alamat",
          field: "address",
        },
        {
          label: "Kota",
          field: "city",
        },
        {
          label: "Kode Cabang",
          field: "code_branch",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "asc",
        per_page: 10,
      },
      slotAddBranchItem: "",
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },
  watch: {
    result(value) {
      this.rows = value;
      this.items = value.data.data;
    },
    slotAddBranch(value) {
      this.slotAddBranchItem = value;
    },
    filter: {
      handler(value) {
        this.$emit("filterBank", value);
      },
      deep: true,
    },
  },
  created() { },
  mounted() { },
  methods: {
    alertMaximum() {
      errorNotification(
        this,
        "Slot sudah terpenuhi",
        "Batas slot cabang sudah terpenuhi silahkan Tambah Berlangganan"
      );
      this.$router.push({
        name: "master-billing.index",
        query: { filter: "tambahan" },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #fff;
}
</style>
